import React,{ useState, useContext } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import {httpRequest} from "../../services/axiosService"
import {signToken} from  "../../utils/jsrsasign"
import { Login,GetSessionToken } from "../../middleware/auth"
import { UserContext } from "../../contexts/UserContext";
import "./index.css"
import Roules from '../../utils/rules';
import { Button,Card, Form, Input, Select, Space,Alert , Col, Row, Layout,Spin } from 'antd';
import { useNavigate  } from "react-router-dom";
const { Content } = Layout;
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const Index = (props) => {
  const dispatch = useDispatch();
  const reCaptchaError = useSelector((state) => state.collapsed.reCaptchaError);
  const { setUsername } = useContext(UserContext);
  const [alert, setAlert] = React.useState({visible:false,message:"",type:""});
  const [spinning, setSpinning] = React.useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  function onChange(value) {
    form.setFieldsValue({reCaptcha:value})
    dispatch({ type: 'RECAPTCHA_ERROR', value:"" })
  }
  const onFinish = async(values) => {
    values.password=signToken(values.password)
    form.setFieldsValue({ password: values.password})
    setSpinning(true);
    const ajax = await httpRequest.post(process.env.REACT_APP_LOGIN??"",values)
    if(ajax.statusCode==200){
      setAlert({visible:true,message:"Login success",type:"success"})
      Login(ajax['body'])
      setUsername(values.email);
      navigate("/visitors");
    }else{
      window.grecaptcha.reset();
      form.setFieldsValue({reCaptcha:""})
      form.setFieldsValue({ password: ""})
      setAlert({visible:true,message:ajax['msg'],type:"error"})
    }
    setSpinning(false);
  }
  // LOGIN FAILED
  const onFinishFailed = errorInfo => {
    let captcha=form.getFieldError('reCaptcha')[0]
    if(captcha){
      dispatch({ type: 'RECAPTCHA_ERROR', value:captcha })
    }
  };
  const onReset = () => {
    setAlert({visible:false,message:"",type:""})
    form.resetFields();
  };
  
  return (
    <Row className="login-module" type="flex" justify="center" align="middle" style={{minHeight: '100vh',backgroundColor:"rgb(240, 242, 245)"}}>
      <Col span={8}>  
        <Card title="Login" bordered={false}>
          <Spin spinning={spinning} fullscreen />
          { alert.visible &&
            <Alert message={alert.message} type={alert.type} />
          }
          <Form layout="vertical" name="control-hooks" form={form}
            initialValues={{ remember: true }} onFinish={onFinish}
            onFinishFailed={onFinishFailed} style={{ maxWidth: 600}}>
            <Form.Item name="email" label="E-mail" rules={Roules.email}>
              <Input placeholder="Type your email" />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={Roules.password} hasFeedback>
              <Input.Password placeholder="Type your password" />
            </Form.Item>
            <Form.Item name="reCaptcha" hidden={true} label="re-captcha" rules={Roules.reCaptcha}>
              <Input placeholder="re-captcha" />
            </Form.Item>
            <div className="g-recaptcha" data-size="compact">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onChange}
              />
              <div style={{color:"#f5222d"}}>
                {reCaptchaError}
              </div>
            </div>
            <Form.Item {...tailLayout}>
              <Space>
                <Button type="primary" ghost htmlType="submit">
                  Login
                </Button>
                <Button ghost type="primary" htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
export default Index;