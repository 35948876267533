import React,{ useState,useEffect,useRef } from 'react';
import {Tabs, Spin,Form,Select,Input, Card, Col, Row, Button,Table, Space,Modal,message} from 'antd';
import Roules from '../../utils/rules';
import {httpRequest} from "../../services/axiosService"
import { GetSessionData } from "../../middleware/auth"
import {signToken} from  "../../utils/jsrsasign"
import UserPassword from "./UserPassword"
import UserProfile from "./UserProfile"
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined
  } from '@ant-design/icons';
// const formType = {
//   ADD_USER:false,
//   EDIT_PROFILE:false,
//   EDIT_PASSWORD:false
// }
const Index = () => {
    const [tab, setTab] = React.useState("1");
    const [userId, setUserId] = React.useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const [spinning, setSpinning] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users, setUsers] = useState<any>([]);
    const rule:any=Roules
    useEffect(() => {  
      (async () => {
        let results = await tableSource()
        if(results.statusCode==200)
          setUsers(results['body']['Items'])
      })();
    }, []);
  const tableSource = async()=>{
    setSpinning(true);
    let results = await httpRequest.get(process.env.REACT_APP_GET_USERS??"")
    setSpinning(false);
    return results
  }
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
        title: 'Action',
        key: 'action',
        render: (row:any) => (
          <Space size="middle">
            <a><EditOutlined onClick={()=>{updateUser(row.id)}}/></a>
            { GetSessionData().id!=row.id &&
            <a><DeleteOutlined onClick={()=>{deleteUser(row.id)}}/></a>
            }
          </Space>
        ),
    },
  ];  
  const sleep = async (milliseconds:number) => {
    await new Promise(resolve => {
      return setTimeout(resolve, milliseconds)
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  }
  const hideModal = () => {
    setIsModalOpen(false);
  }
  const [form]:any = Form.useForm();
  const onFormFinish = async(formName:string,values:any,form:any) => {
    let ajax:any;
    setSpinning(true);
    if(formName=="addUser"){
      values.password=signToken(values.password)
      form.setFieldsValue({ password: values.password})
      form.setFieldsValue({ confirm_password: values.password})
      delete values.confirm_password;
      ajax = await httpRequest.post(process.env.REACT_APP_ADD_USER??"",values)
      if(ajax.statusCode==200){
        setUsers((prevState:any) => [...prevState, {
          id:ajax['body'],
          email:values.email,
          role:values.role
        }])
      }
    }else if(formName=="userProfile"){
      values.id=userId
      ajax = await httpRequest.put(process.env.REACT_APP_EDIT_USER_PROFILE??"",values)
      if(ajax.statusCode==200){
        const index = users.findIndex((item:any) => item.id == ajax['body']);
        users[index].email=values.email
        users[index].role=values.role
        setUsers(users)
      }
    }else if(formName=="userPassword"){
      values.id=userId
      values.password=signToken(values.password)
      form.setFieldsValue({ password: values.password})
      form.setFieldsValue({ confirm_password: values.password})
      delete values.confirm_password;
      ajax = await httpRequest.put(process.env.REACT_APP_EDIT_USER_PASSWORD??"",values)
    }else{
      console.log("Wrong form name")
    }
    if(ajax.statusCode==200){
      hideModal()
      messageApi.open({
        type: 'success',
        content: ajax.msg,
      });
    }else{
      form.setFieldsValue({ password: ""})
      form.setFieldsValue({ confirm_password: ""})
      messageApi.open({
        type: 'error',
        content: ajax.msg,
      });
    }
    setSpinning(false);
  }
  const addUser = () =>{
    setUserId("")
    form.resetFields();
    showModal()
  }
  const onReset = () => {
    form.resetFields();
  }
  const updateUser = async(id:any)=>{
    setUserId(id)
    setTab("1")
    let row:any=users.find((x:any) => x.id === id)
    setIsModalOpen(true);
    await sleep(500)
    form.setFieldsValue({ email: row.email})
    form.setFieldsValue({ role: row.role})
  }
  const deleteUser:any = async(id:any)=>{
    if (window.confirm("are you sure you want to remove the item?")) {
      if(GetSessionData().id==id) return false
      setSpinning(true);
      let results:any = await httpRequest.delete(process.env.REACT_APP_DELETE_USER??"",
        {id:id}
      )
      if(results.statusCode===200){
        const data = users.filter((item:any) => item.id !== id);
        setUsers(data)
      }
      setSpinning(false);
    }
  }
  
  return (
    <>
    <Row gutter={16}>
      <Spin spinning={spinning} fullscreen />
      <Col span={24}>
        <Card title="Manage users" bordered={false}
         extra={<Button type="primary" onClick={addUser}>Add User</Button>}>
          <Table rowKey={(record:any) => record.id} dataSource={users} columns={columns} />
        </Card>
      </Col>
    </Row>
    <Modal destroyOnClose={true} title={<><b>{userId?"Edit":"Add"} User</b> <CloseOutlined onClick={hideModal} style={{float:"right",cursor:"pointer"}}/> </>} open={isModalOpen} 
        okText="Submit" onOk={form.submit}
        closable={false}
        cancelText="Reset" onCancel={onReset}>
        {contextHolder}
        <Spin spinning={spinning} fullscreen />
        <Form.Provider onFormFinish={(name, { values, forms }) => {
          onFormFinish(name,values,form)
        }}>
          {!userId ? (
            <span>
              <Form preserve={false} layout="vertical" name="addUser" form={form} style={{ maxWidth: 600}}>
                <UserProfile/>
                <UserPassword/>
              </Form>
            </span>
          ) : (
            <Tabs 
              defaultActiveKey="1"
              centered 
              destroyInactiveTabPane={false}
              onChange={(key:any) => {
                setTab(key);
              }}
              type="card"
              accessKey={tab}
              items={[
                {
                  key: '1',
                  label: 'Update Profile',
                  forceRender: false,
                  children: tab=="1"?<Form preserve={false} layout="vertical" name="userProfile" form={form} style={{ maxWidth: 600}}><UserProfile/></Form>:""
                },
                {
                  key: '2',
                  label: 'Update Password',
                  forceRender: false,
                  children: tab=="2"?<Form preserve={false} layout="vertical" name="userPassword" form={form} style={{ maxWidth: 600}}><UserPassword/></Form>:""
                }
              ]} />
          )}
        </Form.Provider>
    </Modal>
    </>
  )
}

export default Index