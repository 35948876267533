import React,{ useState,useEffect,useRef } from 'react';
import {Form,Select,Input} from 'antd';
import Roules from '../../utils/rules';

const Index = () => { 
    const rule:any=Roules
    return (
    <>
      <Form.Item name="email" label="E-mail" rules={rule.email}>
        <Input placeholder="Type your email" />
      </Form.Item>
      <Form.Item name="role" label="Role" rules={rule.required} hasFeedback>
        <Select
          placeholder="Role"
          style={{ width: "100%" }}
          options={[
            { value: 'viewer', label: 'Viewer' },
            { value: 'developer', label: 'Developer' },
            { value: 'admin', label: 'Admin' }
          ]}
        />
      </Form.Item>
    </>
  )
}

export default Index