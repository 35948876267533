import React,{ useState,useEffect,useRef } from 'react';
import {Form,Input} from 'antd';
import Roules from '../../utils/rules';

const Index = () => { 
    const rule:any=Roules
    return (
    <>    
      <Form.Item name="password" label="Password" rules={rule.password} hasFeedback>
        <Input.Password placeholder="Type your password" />
      </Form.Item>
      <Form.Item name="confirm_password" label="Confirm Password" rules={rule.confirm_password} hasFeedback>
        <Input.Password placeholder="Re-Type your confirm password" />
      </Form.Item>
    </>
  )
}

export default Index