import axios from 'axios';
import {signToken} from  "../utils/jsrsasign"
import { Logout,GetSessionToken } from "../middleware/auth"

class HttpRequest {
  api: any;
  constructor() {
    this.api = axios.create();
    this.api.interceptors.request.use((config:any)=>{
      document.body.classList.add('loading-indicator');
      config.headers['Authorization'] = signToken()
      if(GetSessionToken()) config.headers['Session-id'] = GetSessionToken()
      config.headers['Content-Type'] = 'application/json'
      return config
    },(error:any)=> {
      return Promise.reject(error);
    });
    this.api.interceptors.response.use(
      (response: any) => {
        document.body.classList.remove('loading-indicator');
        return response.data
      },
      (error: any) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if(error.response.status==401){
            Logout()
            window.location.reload();
          }
          if(error.response.status==400){
            return error.response.data
          }
          console.log('response Error', error.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log('request Error', error.message);

        } else {
          console.log('Error', error.message);
        }
      }
    );
  }
  async post(url:string,data: any) {
    return this.api.post(url, data);
  }
  async put(url:string,data: any) {
    return this.api.put(url, data);
  }
  async get(url:string,params?: any) {
    return this.api.get(url,{ params: params });
  }
  async delete(url:string,params?: any) {
    return this.api.delete(url,{ params: params });
  }
}

export const httpRequest = new HttpRequest();