/* eslint-disable no-template-curly-in-string */
/* For validating antd form field validation */

export default {
    email: [
        {
            type: 'email',
            message: 'The input is not valid E-mail!',
        },
        {
            required: true,
            message: 'Please input your E-mail!',
        }
    ],
    reCaptcha: [
      {
        required: true,
        message: "Please verify re-captcha!"
      }
    ],
    password: [
      {
        required: true,
        message: "Please input your password!"
      }
    ],
    required: [
      {
        required: true,
        message: "Field is required!",
      },
    ],
    number: [
      {
        required: true,
        type: "number",
      },
    ],
    confirm_password:[
      {
        required: true,
        message: 'Please confirm your password!',
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('The new password that you entered do not match!'));
        },
      }),
    ],
    validateMessages: {
      required: "${label} is required!",
      types: {
        email: "${label} is not validate email!",
        number: "${label} is not a validate number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    },
};