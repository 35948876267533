import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { IsLogin } from '../middleware/auth';
import {Login,Dashboard,Visitors,SharedPdf} from '../pages';

export const PublicRoute = () => {
    const isAuthenticated  = IsLogin()
    if (isAuthenticated) {
        return <Navigate to="/visitors" />
    }

    return <Outlet />
}