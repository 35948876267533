const initState = {
  collapsed: false,
  reCaptchaError:"",
  data:[]
 }
 /*Reference from https://github.com/Sunnysit/react-redux-hooks-example/tree/master*/
 const collapsedReducer = (state = initState, action) => {
     switch (action.type) {
       case 'COLLAPSED_MENU':
         return {...state,
          collapsed:action.value
         }
       case 'RECAPTCHA_ERROR':
          return {...state,
            reCaptchaError:action.value
          }
        case 'DATA_ARRAY':
          return {...state,
            data:action.value
          }
      case 'DECREMENT_COUNT_TEN':
          return {...state,
            collapsed:state.counter-10      
        }
       case 'DECREMENT_COUNT':
           return {...state,
            collapsed:state.counter-1      
     }
       case 'RESET_COUNT':
           return {...state,
            collapsed:0     
     } 
       default:
         return state
     }
   }
 
 export default collapsedReducer;