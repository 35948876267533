import React, { useState,useContext} from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate,Link  } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import {httpRequest} from "../../services/axiosService"

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined 
} from '@ant-design/icons';
import { Layout, Button, theme, Spin } from 'antd';
import { Logout } from "../../middleware/auth"
const { Header } = Layout;

const Index = () => {
  const [spinning, setSpinning] = React.useState(false);
  const { username } = useContext(UserContext);
  const navigate = useNavigate();
  //Use for all the dispatch actions
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const SignOut = async()=>{
    setSpinning(true);
    await httpRequest.delete(process.env.REACT_APP_LOGOUT??"")
    Logout()
    setSpinning(false);
    navigate("/sign-in");
  }
  
  return (
      <Header style={{ padding: 0, background: colorBgContainer }}>
        <Spin spinning={spinning} fullscreen />
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => {
            setCollapsed(!collapsed)
            dispatch({ type: 'COLLAPSED_MENU', value:!collapsed })
          }}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
          }}
        />
        <span>Welcome {username}</span>
        <a 
          title='Logout'
          style={{color:'red', fontSize: '16px', width: 64,height: 64, float: "right" }} 
          onClick={()=>SignOut()}>
            <LogoutOutlined />
        </a>
      </Header>
  )
}

export default Index