/* Authetifacation actions */
import { message } from 'antd';
import {httpRequest} from "../services/axiosService"
// LOGIN
export const Login = (data:any) => {
    if(data) {
        localStorage.setItem('auth', JSON.stringify(data))
        return true
    }
}

// LOGOUT
export const Logout = () => {
    localStorage.removeItem('auth')
    message.success('Logout Success')
    return true
}

// LOGIN STATUS
export const IsLogin = () => {
    if (localStorage.getItem('auth')) return true;
    return false;
}
// Get Login User
export const GetSessionToken = () => {
    if(IsLogin()) {
        return JSON.parse(localStorage.getItem('auth')??"").token
    }
    return ""
}
export const GetSessionData = () => {
    if(IsLogin()) {
        return JSON.parse(localStorage.getItem('auth')??"")
    }
    return ""
}