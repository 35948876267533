import React from 'react';
import { Route, Navigate,Outlet  } from 'react-router-dom';
import { IsLogin } from '../middleware/auth';
import {Header,Sidebar} from "../layout"
import {Layout,theme} from 'antd';
const { Content } = Layout;

export const PrivateRoute = () => {
    const isAuthenticated  = IsLogin()
    if (!isAuthenticated) {
        return <Navigate to="/sign-in" />
    }
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();
    return (
        <Layout style={{height:"100vh"}}>
            <Sidebar/>
            <Layout>
                <Header/>
                <Content
                style={{
                    overflow: 'auto',
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}>
                    <Outlet />
                </Content>
          </Layout>  
        </Layout>
    );
}